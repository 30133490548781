import React from 'react';
import PropTypes from 'prop-types';
import { uid } from 'react-uid';
import { graphql } from 'gatsby';

import { Paragraphs, Layout, SEO, PortfolioBanner } from 'components';

const StoriesList = ({
  location: { pathname: siteUrl },
  data: {
    nodePage: {
      title,
      field_metatags,
      field_automatic_breadcrumbs,
      relationships: { field_content, field_header_paragraph, field_breadcrumbs },
    },
  },
}) => (
  <Layout
    headerData={[]}
    isDefaultBreadcrumbs={field_automatic_breadcrumbs}
    customBreadcrumbs={field_breadcrumbs}
    nodeTitle={title}
    type="front stories-list"
  >
    <SEO
      title={field_metatags?.title || title}
      description={field_metatags?.description || ''}
      keywords={field_metatags?.keywords || ''}
      robots={field_metatags?.robots}
      currentUrl={siteUrl}
    />
    {field_header_paragraph && <PortfolioBanner data={field_header_paragraph} title={title} />}
    {field_content.map((item) => (
      <Paragraphs key={uid(item)} data={item} />
    ))}
  </Layout>
);

StoriesList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  data: PropTypes.shape({
    nodePage: PropTypes.shape({
      title: PropTypes.string,
      field_metatags: PropTypes.object,
      field_automatic_breadcrumbs: PropTypes.bool,
      relationships: PropTypes.shape({
        field_content: PropTypes.array,
        field_header_paragraph: PropTypes.array,
        field_breadcrumbs: PropTypes.array,
      }),
    }),
  }),
};

export default StoriesList;

export const query = graphql`
  {
    nodePage(path: { alias: { eq: "/stories-list" } }) {
      ...NodeStoriesListPage
    }
  }
`;
